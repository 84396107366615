.Events {
  background: var(--main-background-color);
  pointer-events: all;
}

.loader {
  position: absolute;
  width: var(--sidebar-width);
  text-align: center;
  margin-top: 40px;
}

._isLoading {
  opacity: 0.3;
  pointer-events: none;
}

.header {
  height: 5rem;
  padding: 0 1.5rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-background-color);
}

@media only screen and (max-width: 720px) {
  .Events {
    /* Offset for android browser headers */
    height: calc(100vh - 12rem - 5.5rem);
    width: 100%;
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-overflow-scrolling: touch;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow: hidden;
  }

  .Events.closed {
    transform: translate3d(0, calc(100vh - 18rem - 5.5rem), 0);
  }

  /* CSS specific to iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    .Events {
      /* Offset for iOS browser headers */
      height: calc(100vh - 12rem - 7.5rem);
    }

    .Events.closed {
      transform: translate3d(0, calc(100vh - 18rem - 7.5rem), 0);
    }
  }

  .Events.opened {
    transform: translate3d(0, 0, 0);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: scroll;
  }

  .header {
    height: 6rem;
    background-repeat: no-repeat;
    background-position: center 1rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="4"><path fill="#FFF" fill-opacity=".5" fill-rule="nonzero" d="M2 0h20a2 2 0 1 1 0 4H2a2 2 0 1 1 0-4z"/></svg>');
  }

  .timeSelect {
    display: none;
  }
}

.eventsLimit {
  padding: 2rem;
  display: block;

  /* Fixed color because opacity doesn't work with sticky */
  color: #828da8;
  line-height: 2rem;
  font-style: italic;
}

.contentWrapper {
  width: 100%;
}
