.mapDashboard {
  position: absolute;
  top: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  height: calc(100vh - 7.2rem);
  z-index: 0;
}

.Map {
  position: relative;
  height: 100%;
  background: lightcyan;
  flex: 1;
  z-index: 0;
}

.mapTools {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.miniGlobe {
  position: absolute;
  top: 2.6rem;
  right: 8rem;
}

@media only screen and (max-width: 720px) {
  .mapDashboard {
    left: 0;
    top: 6rem;
    width: 100%;
    height: calc(100vh - 12rem);
  }
}
