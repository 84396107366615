.container {
  overflow: auto;
  height: calc(100vh - 60px);
}

.resultsList {
  pointer-events: auto;
}

.resultItem {
  display: block;
  padding: 1.5rem 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  line-height: 2rem;
  cursor: pointer;
}

.resultItemDisabled {
  padding: 1.5rem 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  line-height: 2rem;
  cursor: initial;
}

.resultItem:hover {
  background-color: var(--white-hover);
}

.resultItemDisabled:hover {
  background-color: transparent;
}

.resultItem .name {
  text-transform: uppercase;
}

.resultItem .flag {
  font-size: 1.8rem;
}

.resultItem mark {
  background-color: var(--light-blue);
  color: var(--white);
}

.resultItem .subsection {
  display: block;
  font-weight: 300;
}

@media only screen and (max-width: 720px) {
  .resultsList {
    background: var(--main-background-color);
    min-height: 100vh;
  }
}
