.container {
  display: flex;
  width: 42px;
  margin-top: 2px;
}

.elementContainer {
  transition: transform 0.3s linear;
  transform: translateX(0%);
  list-style: none;
}

.elementContainerActive {
  z-index: 1;
  transform: translateX(-0%);
}

.container:hover .elementContainer {
  /* Quick hack to make it work with 3 languages review for more options when available */
  transform: translateX(-200%) !important;
}

.element {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ui-btn-color);
  color: var(--white);
  cursor: pointer;
  width: 42px;
  height: 42px;
  font-family: var(--font-family);
  font-size: var(--font-size);
}

.element:hover,
.element:focus {
  background-color: var(--ui-btn-color-hover);
  outline: none;
}
