.MapCoords {
  position: absolute;
  bottom: 8px;
  z-index: 1;
  left: 88px;
  text-shadow: 2px 2px 5px black;
  text-transform: uppercase;
  cursor: pointer;
}

.center {
  opacity: 0.5;
  padding-left: 16px;
}
