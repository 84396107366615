.header {
  background: var(--main-background-color-hover);
  height: 5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.title {
  display: flex;
  align-items: center;
}

.title::before {
  content: '';
  width: 3rem;
  height: 3rem;
  margin-right: 0.6rem;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
}

.encounter .title::before {
  background-color: var(--encounter-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="12"><path fill="%23061B52" fill-rule="evenodd" d="M11 6.82L7.68 3.5 11 .18v2.5h5.82v1.64H11v2.5zm-5 .86v-2.5L9.32 8.5 6 11.82v-2.5H.18V7.68H6z"/></svg>');
}

.unregistered .title::before {
  background-color: var(--unregistered-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="%23061B52" fill-rule="evenodd" d="M6.66 11.69c1.27 0 2.35-.45 3.24-1.33a4.39 4.39 0 0 0 1.33-3.22c0-1.27-.45-2.34-1.33-3.23A4.39 4.39 0 0 0 6.68 2.6c-1.27 0-2.34.44-3.23 1.32a4.39 4.39 0 0 0-1.33 3.23c0 1.26.45 2.33 1.33 3.22a4.37 4.37 0 0 0 3.2 1.33zm0-10.4c1.61 0 3 .58 4.14 1.73a5.64 5.64 0 0 1 1.72 4.14c0 1.61-.58 2.98-1.72 4.1a5.71 5.71 0 0 1-4.14 1.68 5.58 5.58 0 0 1-4.1-1.68 5.58 5.58 0 0 1-1.69-4.1c0-1.62.56-3 1.68-4.14a5.52 5.52 0 0 1 4.1-1.72zM.02 3.8V.49h3.32L.02 3.8zm3.32 10H.02v-3.31l3.32 3.32zm10-3.31v3.32h-3.32l3.32-3.32zm-3.32-10h3.32V3.8L10.02.48z"/></svg>');
}

.gap .title::before {
  background-color: var(--gap-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18"><path fill="%23061B52" fill-rule="evenodd" d="M11.7 13.15L4.35 5.8A5.18 5.18 0 0 0 3.26 9c0 1.45.51 2.69 1.53 3.7a5.05 5.05 0 0 0 3.71 1.54c1.17 0 2.24-.36 3.2-1.1zM1.75 3.2l.95-.95L15.25 14.8l-.95.95-1.55-1.55a6.56 6.56 0 0 1-3.51 1.51v1.55H7.76v-1.55a6.52 6.52 0 0 1-4.04-1.93 6.52 6.52 0 0 1-1.93-4.04H.24V8.26h1.55a6.56 6.56 0 0 1 1.5-3.51L1.76 3.2zm13.46 5.06h1.55v1.48h-1.55a6.66 6.66 0 0 1-.73 2.39L13.35 11c.26-.63.39-1.3.39-2 0-1.45-.51-2.69-1.53-3.7A5.05 5.05 0 0 0 8.5 3.75c-.7 0-1.37.13-2 .39L5.37 3.02a7.3 7.3 0 0 1 2.4-.73V.74h1.47v1.55c1.57.16 2.92.8 4.04 1.93a6.52 6.52 0 0 1 1.93 4.04z"/></svg>');
}

.port .title::before {
  background-color: var(--port-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><g fill="none" fill-rule="nonzero" transform="translate(.5 1)"><circle cx="7" cy="2" r="2" stroke="%23061B52" stroke-width="1.4"/><path stroke="%23061B52" stroke-width="1.4" d="M1.46 9.3a6 6 0 0 0 11.08 0"/><path fill="%23061B52" d="M10 8h4v4zM4 8H0v4z"/><path stroke="%23061B52" stroke-linecap="square" stroke-width="1.4" d="M7 4.61v7.89"/></g></svg>');
}

.countWrapper {
  display: flex;
  align-items: center;
}

.count {
  opacity: 0.5;
}

.header svg {
  margin: 1rem;
  transform: rotate(180deg);
}

.header:hover {
  background: var(--main-background-color-hover);
}

.header:hover .count {
  opacity: 1;
}

.contentHidden .header {
  background: var(--main-background-color);
}

.contentHidden .header:hover {
  background: var(--main-background-color-hover);
}

.contentHidden .contentWrapper {
  display: none;
}

.contentHidden svg {
  transform: rotate(0);
}

.option {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.option:hover,
.option:focus {
  background: var(--blue-hover);
}

@media only screen and (max-width: 720px) {
  .header {
    height: 6rem;
  }
}
