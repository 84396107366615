.Header {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--main-background-color);
  padding: 1rem 1.5rem;
  pointer-events: all;
}

@media only screen and (max-width: 720px) {
  .Header {
    box-shadow: 0 0 40px 1px #0004;
  }
}

.logoutBtn {
  background-color: transparent;
  border: none;
  padding: 1rem;
  color: var(--white);
  font-size: var(--small-font-size);
  transition: opacity 0.2s linear;
  opacity: 0.5;
}

.logoutBtn:hover {
  opacity: 0.8;
}
