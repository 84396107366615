.timebarWrapper {
  position: fixed;
  bottom: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  z-index: 1;
}

:global(.vessel-event:not(.-encounter)) {
  cursor: initial;
}

@media only screen and (max-width: 720px) {
  .timebarWrapper {
    width: 100%;
    left: 0;
  }
}
