@import url('https://fonts.googleapis.com/css?family=Roboto:500,300');

:global(*) {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:global(html) {
  font-size: 10px;
  position: fixed;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:global(body) {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* COLORS */
  --main-background-color: #061b52;
  --main-background-color-hover: #1f3263;
  --main-border-color: #1e365a;
  --secondary-background-color: #2c3d6c;
  --tertiary-background-color: #163f89;
  --ui-btn-color: #163f89;
  --ui-btn-color-hover: #2d5295;
  --ui-btn-action-color: #e74327;
  --cta-btn-color: #46a4b9;
  --cta-btn-hover: #58acbf;
  --light-blue: #4fc6d7;
  --white: #fff;
  --white-hover: rgba(255, 255, 255, 0.1);
  --blue-hover: rgba(6, 27, 82, 0.1);
  --encounter-color: #e9667f;
  --unregistered-color: #9a84ed;
  --gap-color: #ffe7a0;
  --port-color: #45a3bb;

  /* TYPE */
  --font-family: 'Roboto', sans-serif;
  --extra-large-font-size: 2rem;
  --font-size: 1.4rem;
  --font-weight: 500;
  --small-font-size: 1.2rem;

  /* OTHER */
  --hover-transition: background-color 150ms linear;
  --sidebar-width: 32rem;
}
