.banner {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: 400;
  min-height: 100px;
  padding: 20px;
  width: 100%;
  z-index: 21;
}

.actionButton {
  margin-left: auto;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--white-hover);
  outline: none;
}

.iconClose {
  fill: var(--white);
}

.notification {
  background-color: var(--main-background-color-hover);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--white);
}

.warning {
  background-color: var(--gap-color);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--main-background-color);
}

.warning .actionButton > .iconClose {
  fill: var(--main-background-color);
}

.warning .actionButton:hover,
.warning .actionButton:focus {
  background-color: var(--blue-hover);
  outline: none;
}

.error {
  background-color: var(--ui-btn-action-color);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--white);
}

.error .actionButton > .iconClose {
  fill: var(--white);
}
