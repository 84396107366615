.App {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  color: var(--white);
  overflow: hidden;
  background-color: var(--main-background-color);
  min-height: 100vh;
}

button {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  display: block;
  border: none;
  cursor: pointer;
  border-radius: 0;
}

.mapWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 720px) {
  .App {
    display: block;
  }

  .mapWrapper {
    position: absolute;
    width: 100%;
    height: calc(100% - 12rem);
    top: 12rem;
    left: 0;
  }
}

.notificationBtn {
  display: inline;
  background-color: transparent;
  text-decoration: underline;
  color: currentColor;
}
