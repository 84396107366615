.Search {
  height: 6rem;
  position: relative;
  background: var(--main-background-color);
  pointer-events: all;
  padding: 1.5rem;
}

.Search input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid var(--light-blue);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--white);
  padding: 0 5rem 0 0.5rem;
  transition: var(--hover-transition);
  z-index: 0;
}

.Search.expanded {
  position: sticky;
  top: 0;
  padding: 0;
}

.Search.expanded input {
  padding: 0 5rem 0 2rem;
  background-color: var(--white-hover);
}

.Search input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.Search input:focus {
  outline: none;
  background-color: var(--white-hover);
}

.actionButton {
  position: absolute;
  top: 0.9rem;
  right: 0.9rem;
  border-radius: 50%;
  background-color: transparent;
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  pointer-events: none;
}

.actionButton:focus {
  background-color: var(--white-hover);
  outline: none;
}

.Search.expanded .actionButton {
  pointer-events: auto;
}

.iconText {
  position: absolute;
  right: 5rem;
  top: 2.2rem;
  opacity: 0.5;
}

@media only screen and (max-width: 720px) {
  .Search {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
    background: none;
  }

  .Search input {
    display: none;
  }

  .Search.expanded {
    position: relative;
  }

  .Search.expanded input {
    background: var(--main-background-color);
    display: initial;
  }

  .Search .actionButton {
    pointer-events: auto;
  }

  .iconSearch {
    width: 2.2rem;
    height: 2.2rem;
  }

  .iconClose {
    width: 1.8rem;
    height: 1.8rem;
  }
}
