.actionButton {
  width: 4.2rem;
  height: 4.2rem;
  background-color: var(--ui-btn-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  margin-bottom: 0.3rem;
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--ui-btn-color-hover);
  outline: none;
}
