.popupContainer {
  background-color: var(--main-background-color) !important;
  padding: 0.7rem 1.2rem !important;
}

:global(.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip) {
  border-top-color: var(--main-background-color) !important;
}

.popupEvent {
  font-weight: var(--font-weight);
  line-height: 2rem;
}

.popupDate {
  line-height: 2rem;
}

.popupAction {
  font-weight: 300;
  line-height: 2rem;
}

@media only screen and (max-width: 72rem) {
  .attributions {
    display: none;
  }
}

.attributions {
  position: absolute;
  z-index: 6;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  font-size: 10px;
  padding: 3px 7px;
  color: var(--main-background-color);
}

.attributions a {
  color: var(--main-background-color);
}
