@import url(https://fonts.googleapis.com/css?family=Roboto:500,300);
.MapCoords_MapCoords__3B71E {
  position: absolute;
  bottom: 8px;
  z-index: 1;
  left: 88px;
  text-shadow: 2px 2px 5px black;
  text-transform: uppercase;
  cursor: pointer;
}

.MapCoords_center__GB1zi {
  opacity: 0.5;
  padding-left: 16px;
}

.MapTools_actionButton__1oYAt {
  width: 4.2rem;
  height: 4.2rem;
  background-color: var(--ui-btn-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  margin-bottom: 0.3rem;
}

.MapTools_actionButton__1oYAt:hover,
.MapTools_actionButton__1oYAt:focus {
  background-color: var(--ui-btn-color-hover);
  outline: none;
}

.LayerPanel_LayerPanel__3tPrK {
  position: absolute;
  top: 9rem;
  right: 0;
  width: var(--sidebar-width);
  background: var(--main-background-color);
  font-family: var(--font-family);
  cursor: auto;
  z-index: 1;
}

.LayerPanel_header__3kMdx {
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  background: var(--ui-btn-color);
}

.LayerPanel_actionButton__3wSs7 {
  border: none;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  width: 4.2rem;
  height: 4.2rem;
  background-color: var(--ui-btn-color);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.LayerPanel_actionButton__3wSs7:hover,
.LayerPanel_actionButton__3wSs7:focus {
  background-color: var(--ui-btn-color-hover);
  outline: none;
}

.LayerPanel_iconClose__8rLMJ {
  fill: var(--white);
}

.LayerSwitch_LayerSwitch__1Wln2 {
  height: 4.2rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  font-size: 1.2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
}

.LayerSwitch_actionButton__iNXT1 {
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.LayerSwitch_actionButton__iNXT1:hover,
.LayerSwitch_actionButton__iNXT1:focus {
  background-color: var(--white-hover);
  outline: none;
}

.LayerSwitch_infoButton__uLCi3 {
  margin-left: auto;
}

.LayerSwitch_switch__2rrSk {
  width: 2.4rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  margin-right: 0.6rem;
  background-color: var(--white-hover);
  transition: background-color 100ms linear;
}

.LayerSwitch_switch__2rrSk::after {
  content: '';
  display: block;
  top: 0;
  margin-left: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  background-color: var(--white);
  transition: margin-left 100ms linear;
}

.LayerSwitch_active__3VdSc::after {
  margin-left: 1.2rem;
}

.LayerSwitch_switch__2rrSk:focus {
  outline: none;
}

.LayerSwitch_active__3VdSc {
  background-color: var(--ui-btn-color);
}

.LanguageSelector_container__3-FT8 {
  display: flex;
  width: 42px;
  margin-top: 2px;
}

.LanguageSelector_elementContainer__35VHs {
  transition: transform 0.3s linear;
  transform: translateX(0%);
  list-style: none;
}

.LanguageSelector_elementContainerActive__vwJCi {
  z-index: 1;
  transform: translateX(-0%);
}

.LanguageSelector_container__3-FT8:hover .LanguageSelector_elementContainer__35VHs {
  /* Quick hack to make it work with 3 languages review for more options when available */
  transform: translateX(-200%) !important;
}

.LanguageSelector_element__AdUB- {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ui-btn-color);
  color: var(--white);
  cursor: pointer;
  width: 42px;
  height: 42px;
  font-family: var(--font-family);
  font-size: var(--font-size);
}

.LanguageSelector_element__AdUB-:hover,
.LanguageSelector_element__AdUB-:focus {
  background-color: var(--ui-btn-color-hover);
  outline: none;
}

.Loader_Loader__2GN6s,
.Loader_Loader__2GN6s::before,
.Loader_Loader__2GN6s::after {
  border-radius: 50%;
}

.Loader_Loader__2GN6s {
  color: var(--white);
  text-indent: -99999999em;
  margin: 5rem auto;
  position: relative;
  width: 3rem;
  height: 3rem;
  box-shadow: inset 0 0 0 0.3rem;
  transform: translateZ(0);
}

.Loader_Loader__2GN6s::before,
.Loader_Loader__2GN6s::after {
  position: absolute;
  content: '';
}

.Loader_Loader__2GN6s::before {
  width: 50%;
  height: 100%;
  background: var(--main-background-color);
  border-radius: 3rem 0 0 3rem;
  box-shadow: 0 0 0 1px var(--main-background-color);
  top: 0;
  left: 0;
  transform-origin: 1.5rem 1.5rem;
  -webkit-animation: Loader_load2__3fs2B 2s infinite ease 1.5s;
          animation: Loader_load2__3fs2B 2s infinite ease 1.5s;
}

.Loader_Loader__2GN6s::after {
  width: 50%;
  height: 100%;
  background: var(--main-background-color);
  border-radius: 0 3rem 3rem 0;
  box-shadow: 0 0 0 1px var(--main-background-color);
  top: 0;
  left: 50%;
  transform-origin: 0 1.5rem;
  -webkit-animation: Loader_load2__3fs2B 2s infinite ease;
          animation: Loader_load2__3fs2B 2s infinite ease;
}

.Loader_LoaderSecondary__ttR9J::after,
.Loader_LoaderSecondary__ttR9J::before {
  background-color: var(--ui-btn-color);
  box-shadow: 0 0 0 1px var(--ui-btn-color);
}

@-webkit-keyframes Loader_load2__3fs2B {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes Loader_load2__3fs2B {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MapWrapper_popupContainer__1dz4X {
  background-color: var(--main-background-color) !important;
  padding: 0.7rem 1.2rem !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--main-background-color) !important;
}

.MapWrapper_popupEvent__3pLGv {
  font-weight: var(--font-weight);
  line-height: 2rem;
}

.MapWrapper_popupDate__3n33Q {
  line-height: 2rem;
}

.MapWrapper_popupAction__2x4yp {
  font-weight: 300;
  line-height: 2rem;
}

@media only screen and (max-width: 72rem) {
  .MapWrapper_attributions__yYd9W {
    display: none;
  }
}

.MapWrapper_attributions__yYd9W {
  position: absolute;
  z-index: 6;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  font-size: 10px;
  padding: 3px 7px;
  color: var(--main-background-color);
}

.MapWrapper_attributions__yYd9W a {
  color: var(--main-background-color);
}

.MapDashboard_mapDashboard__19jRf {
  position: absolute;
  top: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  height: calc(100vh - 7.2rem);
  z-index: 0;
}

.MapDashboard_Map__2QiNS {
  position: relative;
  height: 100%;
  background: lightcyan;
  flex: 1 1;
  z-index: 0;
}

.MapDashboard_mapTools__25jL7 {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.MapDashboard_miniGlobe__3BxUb {
  position: absolute;
  top: 2.6rem;
  right: 8rem;
}

@media only screen and (max-width: 720px) {
  .MapDashboard_mapDashboard__19jRf {
    left: 0;
    top: 6rem;
    width: 100%;
    height: calc(100vh - 12rem);
  }
}

.TimebarWrapper_timebarWrapper__xH0f8 {
  position: fixed;
  bottom: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  z-index: 1;
}

.vessel-event:not(.-encounter) {
  cursor: auto;
  cursor: initial;
}

@media only screen and (max-width: 720px) {
  .TimebarWrapper_timebarWrapper__xH0f8 {
    width: 100%;
    left: 0;
  }
}

.Login_container__9xIaf {
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: var(--main-background-color);
  min-height: 100vh;
  background-image: url('/images/login-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Login_logoImage__1Hw4Y {
  width: 154px;
  height: auto;
  margin-bottom: 22px;
}

.Login_textContainer__2TynC {
  max-width: 380px;
  margin-left: 110px;
}

@media only screen and (max-width: 72rem) {
  .Login_textContainer__2TynC {
    margin: 0 3rem;
  }
}

.Login_textTitle__1JDtR {
  font-size: var(--extra-large-font-size);
  letter-spacing: 1px;
  line-height: 28px;
}

.Login_textSubtitle__1UfVg {
  font-size: var(--font-size);
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 30px;
  letter-spacing: 0;
}

.Login_link__1tmnl {
  color: var(--white);
  background-color: var(--ui-btn-action-color);
  padding: 1.1rem 4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--font-size);
  font-weight: 400;
  display: inline-block;
  letter-spacing: 0.5px;
}

.Notifications_banner__2nsBw {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: 400;
  min-height: 100px;
  padding: 20px;
  width: 100%;
  z-index: 21;
}

.Notifications_actionButton__3VNv_ {
  margin-left: auto;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.Notifications_actionButton__3VNv_:hover,
.Notifications_actionButton__3VNv_:focus {
  background-color: var(--white-hover);
  outline: none;
}

.Notifications_iconClose__3Cq1w {
  fill: var(--white);
}

.Notifications_notification__2GCjr {
  background-color: var(--main-background-color-hover);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--white);
}

.Notifications_warning__1cb9U {
  background-color: var(--gap-color);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--main-background-color);
}

.Notifications_warning__1cb9U .Notifications_actionButton__3VNv_ > .Notifications_iconClose__3Cq1w {
  fill: var(--main-background-color);
}

.Notifications_warning__1cb9U .Notifications_actionButton__3VNv_:hover,
.Notifications_warning__1cb9U .Notifications_actionButton__3VNv_:focus {
  background-color: var(--blue-hover);
  outline: none;
}

.Notifications_error__3fVtA {
  background-color: var(--ui-btn-action-color);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--white);
}

.Notifications_error__3fVtA .Notifications_actionButton__3VNv_ > .Notifications_iconClose__3Cq1w {
  fill: var(--white);
}

.App_App__Xh-yk {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  color: var(--white);
  overflow: hidden;
  background-color: var(--main-background-color);
  min-height: 100vh;
}

button {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  display: block;
  border: none;
  cursor: pointer;
  border-radius: 0;
}

.App_mapWrapper__3LZ7w {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 720px) {
  .App_App__Xh-yk {
    display: block;
  }

  .App_mapWrapper__3LZ7w {
    position: absolute;
    width: 100%;
    height: calc(100% - 12rem);
    top: 12rem;
    left: 0;
  }
}

.App_notificationBtn__3seSo {
  display: inline;
  background-color: transparent;
  text-decoration: underline;
  color: currentColor;
}

.Search_Search__3CXfv {
  height: 6rem;
  position: relative;
  background: var(--main-background-color);
  pointer-events: all;
  padding: 1.5rem;
}

.Search_Search__3CXfv input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid var(--light-blue);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--white);
  padding: 0 5rem 0 0.5rem;
  transition: var(--hover-transition);
  z-index: 0;
}

.Search_Search__3CXfv.Search_expanded__1Nv-n {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0;
}

.Search_Search__3CXfv.Search_expanded__1Nv-n input {
  padding: 0 5rem 0 2rem;
  background-color: var(--white-hover);
}

.Search_Search__3CXfv input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.Search_Search__3CXfv input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.Search_Search__3CXfv input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.Search_Search__3CXfv input:focus {
  outline: none;
  background-color: var(--white-hover);
}

.Search_actionButton__2qcQK {
  position: absolute;
  top: 0.9rem;
  right: 0.9rem;
  border-radius: 50%;
  background-color: transparent;
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  pointer-events: none;
}

.Search_actionButton__2qcQK:focus {
  background-color: var(--white-hover);
  outline: none;
}

.Search_Search__3CXfv.Search_expanded__1Nv-n .Search_actionButton__2qcQK {
  pointer-events: auto;
}

.Search_iconText__1f_IU {
  position: absolute;
  right: 5rem;
  top: 2.2rem;
  opacity: 0.5;
}

@media only screen and (max-width: 720px) {
  .Search_Search__3CXfv {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
    background: none;
  }

  .Search_Search__3CXfv input {
    display: none;
  }

  .Search_Search__3CXfv.Search_expanded__1Nv-n {
    position: relative;
  }

  .Search_Search__3CXfv.Search_expanded__1Nv-n input {
    background: var(--main-background-color);
    display: inline;
    display: initial;
  }

  .Search_Search__3CXfv .Search_actionButton__2qcQK {
    pointer-events: auto;
  }

  .Search_iconSearch__3MegN {
    width: 2.2rem;
    height: 2.2rem;
  }

  .Search_iconClose__25ic4 {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.Vessels_container__2RiXO {
  overflow: auto;
  height: calc(100vh - 60px);
}

.Vessels_resultsList__cyoKR {
  pointer-events: auto;
}

.Vessels_resultItem__17lY5 {
  display: block;
  padding: 1.5rem 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  line-height: 2rem;
  cursor: pointer;
}

.Vessels_resultItemDisabled__AGgRA {
  padding: 1.5rem 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  line-height: 2rem;
  cursor: auto;
  cursor: initial;
}

.Vessels_resultItem__17lY5:hover {
  background-color: var(--white-hover);
}

.Vessels_resultItemDisabled__AGgRA:hover {
  background-color: transparent;
}

.Vessels_resultItem__17lY5 .Vessels_name__2rGpI {
  text-transform: uppercase;
}

.Vessels_resultItem__17lY5 .Vessels_flag__2ncuT {
  font-size: 1.8rem;
}

.Vessels_resultItem__17lY5 mark {
  background-color: var(--light-blue);
  color: var(--white);
}

.Vessels_resultItem__17lY5 .Vessels_subsection__21tr9 {
  display: block;
  font-weight: 300;
}

@media only screen and (max-width: 720px) {
  .Vessels_resultsList__cyoKR {
    background: var(--main-background-color);
    min-height: 100vh;
  }
}

.Events_Events__TEXfT {
  background: var(--main-background-color);
  pointer-events: all;
}

.Events_loader__vwTec {
  position: absolute;
  width: var(--sidebar-width);
  text-align: center;
  margin-top: 40px;
}

.Events__isLoading__-TAAs {
  opacity: 0.3;
  pointer-events: none;
}

.Events_header__1mXQa {
  height: 5rem;
  padding: 0 1.5rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-background-color);
}

@media only screen and (max-width: 720px) {
  .Events_Events__TEXfT {
    /* Offset for android browser headers */
    height: calc(100vh - 12rem - 5.5rem);
    width: 100%;
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-overflow-scrolling: touch;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow: hidden;
  }

  .Events_Events__TEXfT.Events_closed__15qws {
    transform: translate3d(0, calc(100vh - 18rem - 5.5rem), 0);
  }

  /* CSS specific to iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    .Events_Events__TEXfT {
      /* Offset for iOS browser headers */
      height: calc(100vh - 12rem - 7.5rem);
    }

    .Events_Events__TEXfT.Events_closed__15qws {
      transform: translate3d(0, calc(100vh - 18rem - 7.5rem), 0);
    }
  }

  .Events_Events__TEXfT.Events_opened__3FVQi {
    transform: translate3d(0, 0, 0);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: scroll;
  }

  .Events_header__1mXQa {
    height: 6rem;
    background-repeat: no-repeat;
    background-position: center 1rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="4"><path fill="#FFF" fill-opacity=".5" fill-rule="nonzero" d="M2 0h20a2 2 0 1 1 0 4H2a2 2 0 1 1 0-4z"/></svg>');
  }

  .Events_timeSelect__2h5-G {
    display: none;
  }
}

.Events_eventsLimit__xpqIH {
  padding: 2rem;
  display: block;

  /* Fixed color because opacity doesn't work with sticky */
  color: #828da8;
  line-height: 2rem;
  font-style: italic;
}

.Events_contentWrapper__2J514 {
  width: 100%;
}

.EventsList_header__2foHg {
  background: var(--main-background-color-hover);
  height: 5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.EventsList_title__1UPyo {
  display: flex;
  align-items: center;
}

.EventsList_title__1UPyo::before {
  content: '';
  width: 3rem;
  height: 3rem;
  margin-right: 0.6rem;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
}

.EventsList_encounter__thDrj .EventsList_title__1UPyo::before {
  background-color: var(--encounter-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="12"><path fill="%23061B52" fill-rule="evenodd" d="M11 6.82L7.68 3.5 11 .18v2.5h5.82v1.64H11v2.5zm-5 .86v-2.5L9.32 8.5 6 11.82v-2.5H.18V7.68H6z"/></svg>');
}

.EventsList_unregistered__eFlvj .EventsList_title__1UPyo::before {
  background-color: var(--unregistered-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="%23061B52" fill-rule="evenodd" d="M6.66 11.69c1.27 0 2.35-.45 3.24-1.33a4.39 4.39 0 0 0 1.33-3.22c0-1.27-.45-2.34-1.33-3.23A4.39 4.39 0 0 0 6.68 2.6c-1.27 0-2.34.44-3.23 1.32a4.39 4.39 0 0 0-1.33 3.23c0 1.26.45 2.33 1.33 3.22a4.37 4.37 0 0 0 3.2 1.33zm0-10.4c1.61 0 3 .58 4.14 1.73a5.64 5.64 0 0 1 1.72 4.14c0 1.61-.58 2.98-1.72 4.1a5.71 5.71 0 0 1-4.14 1.68 5.58 5.58 0 0 1-4.1-1.68 5.58 5.58 0 0 1-1.69-4.1c0-1.62.56-3 1.68-4.14a5.52 5.52 0 0 1 4.1-1.72zM.02 3.8V.49h3.32L.02 3.8zm3.32 10H.02v-3.31l3.32 3.32zm10-3.31v3.32h-3.32l3.32-3.32zm-3.32-10h3.32V3.8L10.02.48z"/></svg>');
}

.EventsList_gap__2jSgk .EventsList_title__1UPyo::before {
  background-color: var(--gap-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18"><path fill="%23061B52" fill-rule="evenodd" d="M11.7 13.15L4.35 5.8A5.18 5.18 0 0 0 3.26 9c0 1.45.51 2.69 1.53 3.7a5.05 5.05 0 0 0 3.71 1.54c1.17 0 2.24-.36 3.2-1.1zM1.75 3.2l.95-.95L15.25 14.8l-.95.95-1.55-1.55a6.56 6.56 0 0 1-3.51 1.51v1.55H7.76v-1.55a6.52 6.52 0 0 1-4.04-1.93 6.52 6.52 0 0 1-1.93-4.04H.24V8.26h1.55a6.56 6.56 0 0 1 1.5-3.51L1.76 3.2zm13.46 5.06h1.55v1.48h-1.55a6.66 6.66 0 0 1-.73 2.39L13.35 11c.26-.63.39-1.3.39-2 0-1.45-.51-2.69-1.53-3.7A5.05 5.05 0 0 0 8.5 3.75c-.7 0-1.37.13-2 .39L5.37 3.02a7.3 7.3 0 0 1 2.4-.73V.74h1.47v1.55c1.57.16 2.92.8 4.04 1.93a6.52 6.52 0 0 1 1.93 4.04z"/></svg>');
}

.EventsList_port__2wCyf .EventsList_title__1UPyo::before {
  background-color: var(--port-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><g fill="none" fill-rule="nonzero" transform="translate(.5 1)"><circle cx="7" cy="2" r="2" stroke="%23061B52" stroke-width="1.4"/><path stroke="%23061B52" stroke-width="1.4" d="M1.46 9.3a6 6 0 0 0 11.08 0"/><path fill="%23061B52" d="M10 8h4v4zM4 8H0v4z"/><path stroke="%23061B52" stroke-linecap="square" stroke-width="1.4" d="M7 4.61v7.89"/></g></svg>');
}

.EventsList_countWrapper__-D_P7 {
  display: flex;
  align-items: center;
}

.EventsList_count__2aRND {
  opacity: 0.5;
}

.EventsList_header__2foHg svg {
  margin: 1rem;
  transform: rotate(180deg);
}

.EventsList_header__2foHg:hover {
  background: var(--main-background-color-hover);
}

.EventsList_header__2foHg:hover .EventsList_count__2aRND {
  opacity: 1;
}

.EventsList_contentHidden__3jXXe .EventsList_header__2foHg {
  background: var(--main-background-color);
}

.EventsList_contentHidden__3jXXe .EventsList_header__2foHg:hover {
  background: var(--main-background-color-hover);
}

.EventsList_contentHidden__3jXXe .EventsList_contentWrapper__3Ozl0 {
  display: none;
}

.EventsList_contentHidden__3jXXe svg {
  transform: rotate(0);
}

.EventsList_option__3X20_ {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.EventsList_option__3X20_:hover,
.EventsList_option__3X20_:focus {
  background: var(--blue-hover);
}

@media only screen and (max-width: 720px) {
  .EventsList_header__2foHg {
    height: 6rem;
  }
}

.Event_Event__14lfV {
  padding: 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  line-height: 2rem;
  transition: color 0.3s, background-color 0.3s;
  color: var(--white);
  position: relative;
}

.Event_highlighted__3xpRb {
  border-color: var(--white);
  background-color: var(--main-background-color-hover);
}

.Event_secondary__SVeJP {
  /* Fixed color because opacity doesn't work with sticky */
  color: #828da8;
}

.Event_encounterBtn__2jwLb {
  width: 100%;
  border: 1px solid var(--light-blue);
  border-radius: 0;
  text-transform: uppercase;
  color: var(--white);
  margin-top: 2rem;
  background-color: transparent;
  padding: 1rem;
  outline: none;
  display: none;
}

.Event_encounterBtn__2jwLb:hover {
  background-color: var(--white-hover);
}

.Event_highlighted__3xpRb .Event_encounterBtn__2jwLb {
  display: block;
}

.Event_secondary__SVeJP .Event_encounterBtn__2jwLb {
  border-color: #3282a1;
  color: #828da8;
}

.Event_targetBtn__1czc1 {
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  position: absolute;
  top: 1.4rem;
  right: 1rem;
}

.Event_targetBtn__1czc1 > svg {
  fill: var(--light-blue);
}

.Event_targetBtn__1czc1:hover {
  background-color: var(--white-hover);
}

.Event_targetBtn__1czc1:hover > svg {
  fill: var(--white);
}

.Event_secondary__SVeJP .Event_targetBtn__1czc1 > svg {
  fill: #3282a1;
}

.Event_flag__3kjIo {
  font-size: 1.8rem;
}

.Event_vesselName__WoIEf {
  background: none;
  color: inherit;
  text-align: left;
  display: inline;
  text-decoration: underline;
}

.Event_vesselName__WoIEf > span {
  margin-right: 1rem;
}

.Event_twoColumns__22ylw {
  display: flex;
}

.Event_vesselName__WoIEf + .Event_twoColumns__22ylw {
  margin-top: 1rem;
}

.Event_twoColumns__22ylw:not(:last-child) {
  margin-bottom: 1rem;
}

.Event_column__1EmBL {
  flex: 1 1;
}

.Event_column__1EmBL:not(:first-child) {
  margin-left: 1rem;
}

.Event_label__WYi31 {
  display: block;
}

.Event_data__3oarD {
  font-weight: 300;
}

.VesselProfile_VesselProfile__3Ckls {
  pointer-events: all;
}

.VesselProfile_header__2reFG {
  background: var(--main-background-color);
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 1rem 0 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
}

.VesselProfile_flag__32Q5t {
  font-size: 2.4rem;
  margin-right: 0.5rem;
}

.VesselProfile_name__1VFiG {
  font-size: 1.6rem;
  flex: 1 1;
}

.VesselProfile_actionButton__lqZWM {
  margin-left: auto;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.VesselProfile_actionButton__lqZWM:hover,
.VesselProfile_actionButton__lqZWM:focus {
  background-color: var(--white-hover);
  outline: none;
}

.VesselProfile_targetBtn__2KfGj {
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  position: absolute;
  top: 6.8rem;
  right: 1rem;
}

.VesselProfile_targetBtn__2KfGj > svg {
  fill: var(--light-blue);
}

.VesselProfile_targetBtn__2KfGj:hover {
  background-color: var(--white-hover);
}

.VesselProfile_targetBtn__2KfGj:hover > svg {
  fill: var(--white);
}

.VesselProfile_info__HIY6P {
  background: var(--main-background-color);
  padding: 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
}

.VesselProfile_fields__2wHhi {
  line-height: 2rem;
  list-style: none;
}

.VesselProfile_column__3uC9o {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
}

.VesselProfile_third__3HuoL {
  width: 33%;
}

.VesselProfile_half__1vgzo {
  width: 50%;
}

.VesselProfile_full__2VIoA {
  width: 100%;
}

.VesselProfile_label__31LON {
  display: block;
}

.VesselProfile_data__27lGQ {
  font-weight: 300;
}

.VesselProfile_downloadBtn__oH9ui {
  width: 100%;
  border: 1px solid var(--light-blue);
  border-radius: 0;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
  margin-top: 2rem;
}

.VesselProfile_iconClose__1-MjR {
  fill: var(--white);
}

@media only screen and (max-width: 720px) {
  .VesselProfile_header__2reFG {
    height: 6rem;
  }

  .VesselProfile_info__HIY6P {
    position: absolute;
    width: 100%;
  }

  .VesselProfile_actionButton__lqZWM {
    margin-left: 0.5rem;
    width: 4.2rem;
    height: 4.2rem;
  }

  .VesselProfile_iconInfo__3Hyja {
    width: 2.2rem;
    height: 2.2rem;
  }

  .VesselProfile_iconClose__1-MjR {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.Sidebar_Sidebar__1wG3G {
  position: absolute;
  width: var(--sidebar-width);
  height: 100vh;
  overflow-y: auto;
  background: var(--main-background-color);
  z-index: 2;
}

@media only screen and (max-width: 720px) {
  .Sidebar_Sidebar__1wG3G {
    width: 100%;
    background: none;
    pointer-events: none;
    overflow: hidden;
  }
}

.Header_Header__2gSru {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--main-background-color);
  padding: 1rem 1.5rem;
  pointer-events: all;
}

@media only screen and (max-width: 720px) {
  .Header_Header__2gSru {
    box-shadow: 0 0 40px 1px #0004;
  }
}

.Header_logoutBtn__3UlXp {
  background-color: transparent;
  border: none;
  padding: 1rem;
  color: var(--white);
  font-size: var(--small-font-size);
  transition: opacity 0.2s linear;
  opacity: 0.5;
}

.Header_logoutBtn__3UlXp:hover {
  opacity: 0.8;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  position: fixed;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* COLORS */
  --main-background-color: #061b52;
  --main-background-color-hover: #1f3263;
  --main-border-color: #1e365a;
  --secondary-background-color: #2c3d6c;
  --tertiary-background-color: #163f89;
  --ui-btn-color: #163f89;
  --ui-btn-color-hover: #2d5295;
  --ui-btn-action-color: #e74327;
  --cta-btn-color: #46a4b9;
  --cta-btn-hover: #58acbf;
  --light-blue: #4fc6d7;
  --white: #fff;
  --white-hover: rgba(255, 255, 255, 0.1);
  --blue-hover: rgba(6, 27, 82, 0.1);
  --encounter-color: #e9667f;
  --unregistered-color: #9a84ed;
  --gap-color: #ffe7a0;
  --port-color: #45a3bb;

  /* TYPE */
  --font-family: 'Roboto', sans-serif;
  --extra-large-font-size: 2rem;
  --font-size: 1.4rem;
  --font-weight: 500;
  --small-font-size: 1.2rem;

  /* OTHER */
  --hover-transition: background-color 150ms linear;
  --sidebar-width: 32rem;
}

