.Event {
  padding: 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
  line-height: 2rem;
  transition: color 0.3s, background-color 0.3s;
  color: var(--white);
  position: relative;
}

.highlighted {
  border-color: var(--white);
  background-color: var(--main-background-color-hover);
}

.secondary {
  /* Fixed color because opacity doesn't work with sticky */
  color: #828da8;
}

.encounterBtn {
  width: 100%;
  border: 1px solid var(--light-blue);
  border-radius: 0;
  text-transform: uppercase;
  color: var(--white);
  margin-top: 2rem;
  background-color: transparent;
  padding: 1rem;
  outline: none;
  display: none;
}

.encounterBtn:hover {
  background-color: var(--white-hover);
}

.highlighted .encounterBtn {
  display: block;
}

.secondary .encounterBtn {
  border-color: #3282a1;
  color: #828da8;
}

.targetBtn {
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  position: absolute;
  top: 1.4rem;
  right: 1rem;
}

.targetBtn > svg {
  fill: var(--light-blue);
}

.targetBtn:hover {
  background-color: var(--white-hover);
}

.targetBtn:hover > svg {
  fill: var(--white);
}

.secondary .targetBtn > svg {
  fill: #3282a1;
}

.flag {
  font-size: 1.8rem;
}

.vesselName {
  background: none;
  color: inherit;
  text-align: left;
  display: inline;
  text-decoration: underline;
}

.vesselName > span {
  margin-right: 1rem;
}

.twoColumns {
  display: flex;
}

.vesselName + .twoColumns {
  margin-top: 1rem;
}

.twoColumns:not(:last-child) {
  margin-bottom: 1rem;
}

.column {
  flex: 1;
}

.column:not(:first-child) {
  margin-left: 1rem;
}

.label {
  display: block;
}

.data {
  font-weight: 300;
}
