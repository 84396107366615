.Loader,
.Loader::before,
.Loader::after {
  border-radius: 50%;
}

.Loader {
  color: var(--white);
  text-indent: -99999999em;
  margin: 5rem auto;
  position: relative;
  width: 3rem;
  height: 3rem;
  box-shadow: inset 0 0 0 0.3rem;
  transform: translateZ(0);
}

.Loader::before,
.Loader::after {
  position: absolute;
  content: '';
}

.Loader::before {
  width: 50%;
  height: 100%;
  background: var(--main-background-color);
  border-radius: 3rem 0 0 3rem;
  box-shadow: 0 0 0 1px var(--main-background-color);
  top: 0;
  left: 0;
  transform-origin: 1.5rem 1.5rem;
  animation: load2 2s infinite ease 1.5s;
}

.Loader::after {
  width: 50%;
  height: 100%;
  background: var(--main-background-color);
  border-radius: 0 3rem 3rem 0;
  box-shadow: 0 0 0 1px var(--main-background-color);
  top: 0;
  left: 50%;
  transform-origin: 0 1.5rem;
  animation: load2 2s infinite ease;
}

.LoaderSecondary::after,
.LoaderSecondary::before {
  background-color: var(--ui-btn-color);
  box-shadow: 0 0 0 1px var(--ui-btn-color);
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
