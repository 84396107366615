.LayerPanel {
  position: absolute;
  top: 9rem;
  right: 0;
  width: var(--sidebar-width);
  background: var(--main-background-color);
  font-family: var(--font-family);
  cursor: auto;
  z-index: 1;
}

.header {
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  background: var(--ui-btn-color);
}

.actionButton {
  border: none;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  width: 4.2rem;
  height: 4.2rem;
  background-color: var(--ui-btn-color);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--ui-btn-color-hover);
  outline: none;
}

.iconClose {
  fill: var(--white);
}
