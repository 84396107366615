.LayerSwitch {
  height: 4.2rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  font-size: 1.2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
}

.actionButton {
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--white-hover);
  outline: none;
}

.infoButton {
  margin-left: auto;
}

.switch {
  width: 2.4rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  margin-right: 0.6rem;
  background-color: var(--white-hover);
  transition: background-color 100ms linear;
}

.switch::after {
  content: '';
  display: block;
  top: 0;
  margin-left: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  background-color: var(--white);
  transition: margin-left 100ms linear;
}

.active::after {
  margin-left: 1.2rem;
}

.switch:focus {
  outline: none;
}

.active {
  background-color: var(--ui-btn-color);
}
