.VesselProfile {
  pointer-events: all;
}

.header {
  background: var(--main-background-color);
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 1rem 0 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
}

.flag {
  font-size: 2.4rem;
  margin-right: 0.5rem;
}

.name {
  font-size: 1.6rem;
  flex: 1;
}

.actionButton {
  margin-left: auto;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--white-hover);
  outline: none;
}

.targetBtn {
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--hover-transition);
  position: absolute;
  top: 6.8rem;
  right: 1rem;
}

.targetBtn > svg {
  fill: var(--light-blue);
}

.targetBtn:hover {
  background-color: var(--white-hover);
}

.targetBtn:hover > svg {
  fill: var(--white);
}

.info {
  background: var(--main-background-color);
  padding: 2rem;
  box-shadow: inset 0 -1px 0 0 var(--white-hover);
}

.fields {
  line-height: 2rem;
  list-style: none;
}

.column {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
}

.third {
  width: 33%;
}

.half {
  width: 50%;
}

.full {
  width: 100%;
}

.label {
  display: block;
}

.data {
  font-weight: 300;
}

.downloadBtn {
  width: 100%;
  border: 1px solid var(--light-blue);
  border-radius: 0;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
  margin-top: 2rem;
}

.iconClose {
  fill: var(--white);
}

@media only screen and (max-width: 720px) {
  .header {
    height: 6rem;
  }

  .info {
    position: absolute;
    width: 100%;
  }

  .actionButton {
    margin-left: 0.5rem;
    width: 4.2rem;
    height: 4.2rem;
  }

  .iconInfo {
    width: 2.2rem;
    height: 2.2rem;
  }

  .iconClose {
    width: 1.8rem;
    height: 1.8rem;
  }
}
