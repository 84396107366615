.Sidebar {
  position: absolute;
  width: var(--sidebar-width);
  height: 100vh;
  overflow-y: auto;
  background: var(--main-background-color);
  z-index: 2;
}

@media only screen and (max-width: 720px) {
  .Sidebar {
    width: 100%;
    background: none;
    pointer-events: none;
    overflow: hidden;
  }
}
